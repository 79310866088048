import React, { Component } from "react";
import { User } from "../../api/models/User";
import { ReadyPlayMeViewer } from "../../diverse/readyplayme/ReadyPlayMeViewer";
import FaceIcon from '@mui/icons-material/Face';
import { BaseAvatar } from "./BaseAvatar";

export interface ReadyPlayMeAvatarProps
{
    user: User;
    style?: React.CSSProperties;
    interactable?: boolean;
}

interface ReadyPlayMeAvatarState
{
}

export class ReadyPlayMeAvatar extends Component<ReadyPlayMeAvatarProps, ReadyPlayMeAvatarState>
{
    constructor(props: ReadyPlayMeAvatarProps)
    {
        super(props);

        this.state = {
        };
    }

    public render(): JSX.Element
    {
        return (
            <BaseAvatar style={{ width: 94, height: 94, border: "3px solid #77CCBE", backgroundColor: "#fff", ...this.props.style }}>
                {this.props.user.readyPlayMeGLBUrl ?
                    <ReadyPlayMeViewer
                        src={this.props.user.readyPlayMeGLBUrl}
                        zoomOnLoad={true}
                        interactable={this.props.interactable}
                    />
                    :
                    <FaceIcon />
                }
            </BaseAvatar>
        );
    }
}