import React from "react";
import { createRoot, Root } from "react-dom/client";
import { AppAPI } from "./api/AppAPI";
import { MainUI } from "./ui/MainUI";
import { EventDispatcher } from "./diverse/DIEvent";
import { UserService } from "./services/UserService";
import { Page, PageCallback } from "./Page";
import { NotificationEvent, NotificationIconType } from "./events/NotificationEvent";
import { UserEvent } from "./events/UserEvent";
import { Config } from "./Config";
import { ShapeAnimation } from "./ShapeAnimation";
import { Cart } from "./cart/Cart";
import { Product } from "./api/models/Product";
import { CartChangedEvent } from "./cart/CartChangedEvent";
import { AppStorage } from "./AppStorage";

export class App extends EventDispatcher
{
    private static instance: App;

    private userService: UserService;
    private config: Config;
    private _shapeAnimation: ShapeAnimation;
    private _cart: Cart;

    constructor()
    {
        super();

        this.render();

        this._shapeAnimation = new ShapeAnimation(document.getElementById("canvas"));
        this._cart = new Cart();

        App.instance = this;
    }

    public async onUIReady(): Promise<void>
    {
        this.config = new Config();
        await this.config.load();

        new AppAPI(this.config.apiUrl);
        this.userService = new UserService();

        await this.initializeApp();
    }

    private async initializeApp(): Promise<void>
    {
        const isLoggedIn: boolean = await this.userService.checkAuthenticated();

        if (isLoggedIn === true)
        {
            this.dispatchEvent(new UserEvent(UserEvent.LOGGED_IN));
        }
        else 
        {
            this.dispatchEvent(new UserEvent(UserEvent.LOGGED_OUT));
        }
    }

    public async login(email: string, password: string): Promise<void>
    {
        await this.userService.login({ identifier: email, password: password });
        this.changePage(Page.Store);
        this.dispatchEvent(new UserEvent(UserEvent.LOGGED_IN));
    }

    public async logout(): Promise<void>
    {
        await this.userService.logout();
        this.dispatchEvent(new UserEvent(UserEvent.LOGGED_OUT));
        document.location.reload();
    }

    public changePage(pageRoute: Page, param: string = null): void
    {
        if (document.location.pathname === pageRoute)
        {
            return;
        }

        if (param != null)
        {
            document.location.href = pageRoute + param;
        }
        else 
        {
            document.location.href = pageRoute;
        }
    }

    public setPageTitle(title: string): void
    {
        document.title = `${title} - VR Campus`;
    }

    public sendNotification(message: string, icon: NotificationIconType = NotificationIconType.Default): void
    {
        this.dispatchEvent(new NotificationEvent(NotificationEvent.RECEIVED, message, icon));
    }

    private render(): void
    {
        const root: Root = createRoot(document.getElementById("root") || document.createElement("div"));
        root.render(React.createElement(MainUI, { onUIReadyCB: this.onUIReady.bind(this) }, null));
    }

    public addToCart(p: Product): void 
    {
        if (this._cart.addProduct(p))
        {
            this.dispatchEvent(new CartChangedEvent(CartChangedEvent.CART_UPDATED));
            this.sendNotification(p.name + " added to cart", NotificationIconType.Cart);
        }
        else
        {
            this.dispatchEvent(new CartChangedEvent(CartChangedEvent.PRODUCT_ALREADY_IN_CART));
            this.sendNotification(p.name + " already in cart", NotificationIconType.Cart);
        }
    }

    public removeFromCart(p: Product): void 
    {
        this._cart.removeProduct(p);
        this.dispatchEvent(new CartChangedEvent(CartChangedEvent.CART_UPDATED));
        this.sendNotification(p.name + " removed from cart", NotificationIconType.Cart);
    }

    public resetCart(): void 
    {
        this._cart.reset();
        this.dispatchEvent(new CartChangedEvent(CartChangedEvent.CART_UPDATED));
    }

    public get cartCount(): number { return this._cart.cartCount; }
    public get cartTotal(): string { return this._cart.cartTotal; }
    public get cartItems(): Array<Product> { return this._cart.cartItems; }

    public static get I(): App { return App.instance; }
    public get shapeAnimation(): ShapeAnimation { return this._shapeAnimation; }
}
