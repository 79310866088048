import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import Button from "@mui/material/Button";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LinkIcon from '@mui/icons-material/Link';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Product, ProductType } from "../../api/models/Product";

export interface CartItemProps
{
    product: Product;
    onRemoveClicked: () => void;
}

interface CartItemState 
{
}

export class CartItem extends Component<CartItemProps, CartItemState>
{
    constructor(props: CartItemProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Box key={this.props.product.id} sx={{ display: "flex", mb: 2, borderBottom: "2px solid #fcfcfc" }}>
                <Box
                    sx={{
                        boxShadow: "4px 5px 8px 0px #4b4b4b96",
                        marginRight: 1,
                        borderRadius: 4,
                        height: "80px",
                        width: "80px",
                        backgroundSize: "cover",
                        backgroundImage: `url(${this.props.product.thumbnail.url})`
                    }}
                />
                <Box sx={{ pt: 0, pl: 1, pr: 1, pb: 1 }}>
                    <Box sx={{ pl: 1 }}>
                        <Typography component="h2" sx={{ m: 0, textTransform: "uppercase", fontSize: "0.8rem" }} fontWeight={300}>
                            {ProductType[this.props.product.type]}
                        </Typography>
                        <Typography variant="body1" component="h1" sx={{ mb: 1 }}>
                            {this.props.product.name}
                        </Typography>
                    </Box>
                    <Button variant="text" size="small" onClick={this.props.onRemoveClicked.bind(this)} title="Remove from cart">
                        <DeleteOutlineIcon /> Remove
                    </Button>
                </Box>
                <Box sx={{ marginLeft: "auto" }}>
                    <Typography sx={{ fontWeight: "800", color: "#FC522A" }}>{this.props.product.priceFormatted}</Typography>
                </Box>
            </Box>
        );
    }
}
