import { ForgotPasswordRequest, LoginRequest, RegisterRequest, UpdateUserRequest } from "../api/UserAPI";
import { AppAPI } from "../api/AppAPI";
import { User } from "../api/models/User";
import { APIResponse } from "../api/rest/APIResponse";
import { ErrorEmail } from "../api/errors/ErrorEmail";
import { PaswordValidation } from "../api/validation/PasswordValidator";
import { Validator } from "../api/validation/Validator";
import { AppStorage } from "../AppStorage";
import { Logger } from "../diverse/Logger";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { Campus } from "../api/models/Campus";

export class UserService 
{
    public static user: User = null;
    public static userCampuses: Array<Campus> = [];

    private appStorage: AppStorage;

    constructor()
    {
        this.appStorage = new AppStorage();
    }

    public async login(request: LoginRequest): Promise<User>
    {
        this.appStorage.clearToken();

        if (Validator.hasValidMinInput(request.identifier, 1) === false)
        {
            throw new ErrorEmail("Please enter an email address");
        }
        PaswordValidation.validate(request.password);

        const response: APIResponse = await AppAPI.I.users.login(request);
        APIErrorResponseHandler.validate(response);

        const user: User = User.fromJson(response.json.user);
        UserService.user = user;

        this.appStorage.saveToken(response.json.jwt);

        AppAPI.I.setAuthHeader(response.json.jwt);

        return user;
    }

    public async logout(): Promise<void>
    {
        AppAPI.I.setAuthHeader("");
        UserService.user = null;
        this.appStorage.clearToken();
    }

    public async getUser(): Promise<User>
    {
        const response: APIResponse = await AppAPI.I.users.getUser();
        APIErrorResponseHandler.validate(response);
        const user: User = User.fromJson(response.json);
        return user;
    }

    public async getUserCampuses(): Promise<Array<Campus>>
    {
        const response: APIResponse = await AppAPI.I.users.getUserCampuses();
        APIErrorResponseHandler.validate(response);

        let campuses: Array<Campus> = [];
        for (let i = 0; i < response.json.length; i++)
        {
            let campus: any = response.json[i];
            campuses.push(
                new Campus(
                    campus.id,
                    campus.name,
                    campus.description,
                    campus.file,
                    campus.icon,
                )
            );
        }
        return campuses;
    }

    public async checkAuthenticated(): Promise<boolean>
    {
        try
        {
            const token: string = this.appStorage.token;
            if (token == null)
            {
                return false;
            }

            AppAPI.I.setAuthHeader(token);

            const user: User = await this.getUser();
            UserService.user = user;

            const userCampuses: Array<Campus> = await this.getUserCampuses();
            UserService.userCampuses = userCampuses;

            return true;
        }
        catch (error: any)
        {
            Logger.log(error);
            return false;
        }
    }
}