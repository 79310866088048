import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { Logger } from "../diverse/Logger";
import { App } from "../App";
import { UserService } from "../services/UserService";
import { User } from "../api/models/User";
import { BaseTheme } from "./BaseTheme";
import { Page } from "../Page";
import { HomeStorePage } from "./pages/HomeStorePage";
import { Alert, CssBaseline, Snackbar } from "@mui/material";
import { NotificationEvent, NotificationIconType } from "../events/NotificationEvent";
import { UserEvent } from "../events/UserEvent";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { NotFoundPage } from "./pages/NotFoundPage";
import { StoreCartPage } from "./pages/StoreCartPage";
import StoreDetailPage from "./pages/StoreDetailPage";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { LoginPage } from "./pages/auth/LoginPage";
import { PaymentCompletePage } from "./pages/PaymentCompletePage";

export interface MainProps
{
    onUIReadyCB: () => void;
}

interface MainState 
{
    user: User;
    isLoggedIn: boolean;
    loading: boolean;
    showNotification: boolean;
    notification: string;
    notificationIcon: NotificationIconType;
}

export class MainUI extends Component<MainProps, MainState>
{
    constructor(props: MainProps)
    {
        super(props);

        this.state = {
            isLoggedIn: false,
            loading: true,
            showNotification: false,
            notification: null,
            notificationIcon: null,
            user: null
        };
    }

    public componentDidMount(): void
    {
        this.props.onUIReadyCB();

        App.I.addEventListener(UserEvent.LOGGED_IN, this.onUserLoggedIn, this);
        App.I.addEventListener(UserEvent.LOGGED_OUT, this.onUserLoggedOut, this);
        App.I.addEventListener(NotificationEvent.RECEIVED, this.onNotificationReceived, this);
    }

    private onUserLoggedIn(): void 
    {
        this.setState({
            isLoggedIn: true,
            loading: false,
            user: UserService.user
        });
    }

    private onUserLoggedOut(): void 
    {
        this.setState({
            isLoggedIn: false,
            loading: false,
            user: null
        });
    }

    private onNotificationReceived(event: NotificationEvent): void 
    {
        this.setState({
            notification: event.message,
            notificationIcon: event.icon,
            showNotification: true
        })
    }

    private onCloseNotificationn(): void 
    {
        this.setState({
            showNotification: false,
            notification: null
        });
    }

    public render(): JSX.Element
    {
        return (
            <ThemeProvider theme={BaseTheme()}>
                {/* TODO */}
                <CssBaseline />

                <LocalizationProvider dateAdapter={AdapterDateFns}>

                    <BrowserRouter>
                        <Routes>
                            {this.state.loading === false ?
                                <>
                                    <Route path={Page.Login} element={<LoginPage />} />
                                    <Route path={Page.Store} element={<HomeStorePage />} />
                                    <Route path={Page.StoreDetailRoute} element={<StoreDetailPage />} />
                                    <Route path={Page.StoreCart} element={<StoreCartPage />} />
                                    <Route path={Page.PaymentComplete} element={<PaymentCompletePage />} />
                                    {this.state.isLoggedIn ?
                                        <>
                                            <Route path="*" element={<NotFoundPage />} />
                                        </>
                                        : null
                                    }
                                </>
                                : null
                            }
                        </Routes>
                    </BrowserRouter>

                    <Snackbar
                        open={this.state.showNotification}
                        onClose={this.onCloseNotificationn.bind(this)}
                        autoHideDuration={6000}
                        action={"Close"}
                    >
                        <Alert severity="success" style={{ background: "green", color: "#fff", fontWeight: "bold" }}
                            icon={this.state.notificationIcon === NotificationIconType.Cart ? <ShoppingCartIcon /> : null}>
                            {this.state.notification}
                        </Alert>
                    </Snackbar>

                </LocalizationProvider>
            </ThemeProvider>
        );
    }

    public componentWillUnmount(): void
    {
        App.I.removeEventListener(UserEvent.LOGGED_IN, this.onUserLoggedIn);
        App.I.removeEventListener(UserEvent.LOGGED_OUT, this.onUserLoggedOut);
        App.I.removeEventListener(NotificationEvent.RECEIVED, this.onNotificationReceived);
    }
}
