import { DIEvent } from "../diverse/DIEvent";

export class CartChangedEvent extends DIEvent
{
    public static CART_UPDATED: string = "cartUpdated";
    public static PRODUCT_ALREADY_IN_CART: string = "productAlreadyInCart";

    constructor(type: string)
    {
        super(type);
    }
}
