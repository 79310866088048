import { getCookie, setCookie, removeCookie } from "typescript-cookie";
import { Product } from "./api/models/Product";

export class AppStorage 
{
    private static readonly JWT_KEY: string = "token";
    private static readonly CART_CONTENT: string = "cart";

    public saveToken(token: string): void
    {
        setCookie(AppStorage.JWT_KEY, token);
    }

    public saveCart(cartItems: Array<Product>): void 
    {
        console.log("Saving cart", cartItems);
        localStorage.setItem(AppStorage.CART_CONTENT, JSON.stringify(cartItems));
    }

    public clearCart(): void 
    {
        localStorage.setItem(AppStorage.CART_CONTENT, JSON.stringify([]));
    }

    public get cart(): Array<Product>
    {
        let cartItems: Array<Product> = [];
        let cartItemsJson = JSON.parse(localStorage.getItem("cart"));

        if (cartItemsJson)
        {
            for (let i = 0; i < cartItemsJson.length; i++)
            {
                let cartItem = cartItemsJson[i];
                cartItems.push(
                    new Product(
                        cartItem._id,
                        cartItem._name,
                        cartItem._description,
                        cartItem._stripeProductId,
                        cartItem._thumbnail,
                        cartItem._gallery,
                        cartItem._price,
                        cartItem._shortDescription,
                        cartItem._assetId,
                        cartItem._environmentId,
                        cartItem._groupId,
                        cartItem._isSubscriptionProduct
                    )
                );
            }
        }

        console.log("Cart Items", cartItems);
        return cartItems;
    }

    public clearToken(): void 
    {
        return removeCookie(AppStorage.JWT_KEY);
    }

    public get token(): string
    {
        return getCookie(AppStorage.JWT_KEY) ?? null;
    }
}