import { DIEvent } from "../diverse/DIEvent";

export class NotificationEvent extends DIEvent
{
    public static RECEIVED: string = "notificationReceived";

    constructor(type: string, public message: string, public icon: NotificationIconType = NotificationIconType.Default)
    {
        super(type);
    }
}

export enum NotificationIconType 
{
    Default,
    Cart
}