import { createTheme } from "@mui/material";

export function BaseTheme()
{
    return createTheme({

        palette: {
            mode: "light",
            background: {
                default: "#F4F4F4",
                paper: "#FAFAFA"
            },
            primary: {
                main: "#271E46"
            },
            secondary: {
                main: "#FFFFFF"
            },
            warning: {
                main: "#CB3A3A"
            }
        },
        components: {
            MuiMenu: {},
            MuiButton: {
                variants: [
                    {
                        props: { variant: "contained", color: "secondary" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                            color: "#222133"
                        },
                    },
                    {
                        props: { variant: "contained", color: "secondary" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                        },
                    },
                    {
                        props: { variant: "outlined" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                        },
                    },
                    {
                        props: { variant: "text" },
                        style: {
                            borderRadius: 20,
                            fontWeight: 800,
                        },
                    },
                ],
            },
            MuiLink: {
                variants: [
                    {
                        props: { variant: "body2" },
                        style: {
                            color: "#fff",
                        }
                    }
                ]
            }
        },
        typography: {
            fontFamily: "Space Grotesk, sans-serif",
            fontWeightLight: 300,
            fontWeightRegular: 300,
            fontWeightBold: 600,
            h1: {
                fontSize: "2rem",
                fontWeight: 600,
            },
            h2: {
                fontSize: "1.6rem",
            },
            h3: {
                fontSize: "1.5rem",
            },
            h4: {
                fontSize: "1.4rem",
            },
            h5: {
                fontSize: "1.2rem",
            },
            h6: {
                fontSize: "1rem",
            },
            button: {
                textTransform: "none",
                fontSize: "1rem",
            },
        }
    });
}