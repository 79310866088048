import React, { Component } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { App } from "../../App";
import Header from "../components/Header";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Page } from "../../Page";
import { NotificationIconType } from "../../events/NotificationEvent";

export interface PaymentCompletePageProps
{
}

interface PaymentCompletePageState 
{
    user: User;
}

export class PaymentCompletePage extends Component<PaymentCompletePageProps, PaymentCompletePageState>
{
    private form: React.RefObject<HTMLFormElement>;
    private userService: UserService;

    constructor(props: PaymentCompletePageProps)
    {
        super(props);

        this.state = {
            user: null
        };

        this.form = React.createRef();
        this.userService = new UserService();

        App.I.setPageTitle("Cart");
    }

    public componentDidMount(): void
    {
        // Hacky but if we're here on this page, we've been redirected by stripe
        App.I.resetCart();
        App.I.sendNotification("Congratulations on your purchase, all confirmed.", NotificationIconType.Cart);

        const user: User = UserService.user;

        this.setState({
            user: user
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="lg">

                    <Grid container spacing={5} sx={{ mt: 4 }}>
                        <Grid xs={12} lg={12}>
                            <Box sx={{ background: "#fff", padding: 4, borderRadius: "2rem" }}>
                                <Typography variant="h1" sx={{ mb: 4 }} fontWeight={600}>
                                    Thank you for your purchase {this.state.user?.name}
                                </Typography>

                                <Typography variant="body1">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </Typography>

                                <Box sx={{ mt: 4, mb: 2 }}>
                                    <Button variant="outlined" onClick={() => document.location.href = Page.Store}>
                                        Continue shopping
                                    </Button>
                                </Box>

                                <Typography variant="h3" sx={{ pt: 4, pb: 2 }}>
                                    What happens next?
                                </Typography>

                                <Typography variant="body1" sx={{ pt: 2, pb: 2 }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </Typography>

                                <Typography variant="h3" sx={{ pt: 4, pb: 2 }}>
                                    Some other FAQ's
                                </Typography>

                                <Typography variant="body1" sx={{ pt: 2, pb: 2 }}>
                                    <ul>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                        </li>
                                    </ul>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}