import { FileFormat } from "./FileObject";

export class Product 
{
    constructor(
        private _id: number,
        private _name: string,
        private _description: string,
        private _stripeProductId: string,
        private _thumbnail: FileFormat,
        private _gallery: Array<FileFormat>,
        private _price: ProductPrice,
        private _shortDescription: string,
        private _assetId: string,
        private _environmentId: string,
        private _groupId: string,
        private _isSubscriptionProduct: boolean
    )
    {
    }

    public static fromServerJson(json: any): Product
    {
        return new Product(
            json.id,
            json.name,
            json.description,
            json.stripe_product_id,
            json.thumbnail,
            json.gallery,
            json.price,
            json.short_description,
            json.assetId,
            json.environmentId,
            json.assetGroupId,
            json.is_subscription_product
        );
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get shortDescription(): string { return this._shortDescription; }
    public get description(): string { return this._description; }
    public get stripeProductId(): string { return this._stripeProductId; }
    public get thumbnail(): ProductImage { return { url: this._thumbnail?.url, alt: this._thumbnail?.name }; }
    public get gallery(): Array<ProductImage>
    {
        let galleryImages: Array<ProductImage> = [];
        this._gallery?.map(r => galleryImages.push({ url: r.url, alt: r.name }));
        return galleryImages;
    }

    public get pricePence(): number
    {
        return this._price.unit_amount;
    }

    public get priceFormatted(): string
    {
        let currency: string = "";
        if (this._price.currency === "gbp")
        {
            currency = "£";
        }
        let poundsFromPence = this._price.unit_amount / 100;
        return currency + poundsFromPence;
    }

    public get type(): ProductType
    {
        if (this._assetId)
        {
            return ProductType.Asset;
        }
        if (this._environmentId)
        {
            return ProductType.Environment;
        }
        if (this._groupId)
        {
            return ProductType.Collection;
        }
    }

    public get isSubscriptionProduct(): boolean
    {
        return this._isSubscriptionProduct;
    }
}

export enum ProductType 
{
    Collection,
    Environment,
    Asset
}

export interface ProductPrice
{
    unit_amount: number; // Pence
    currency: string; // e.g gbp
}

export interface ProductImage 
{
    url: string;
    alt: string;
}