import React, { Component } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { App } from "../../App";
import Header from "../components/Header";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FeaturedAsset } from "../components/FeaturedAsset";
import Box from "@mui/material/Box";
import { ProductItem } from "../components/AssetItem";
import { Button } from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Swiper from "../components/Swiper";
import { SwiperSlide } from "swiper/react";
import { Page } from "../../Page";
import { Product, ProductType } from "../../api/models/Product";
import { ProductService } from "../../services/ProductService";
import { RouterProps } from "../RouterProps";
import { withRouter } from "../withRouter";

export interface StoreDetailPageProps extends RouterProps
{
}

interface StoreDetailPageState 
{
    product: Product;
    isLoggedIn: boolean;
}

class StoreDetailPage extends Component<StoreDetailPageProps, StoreDetailPageState>
{
    constructor(props: StoreDetailPageProps)
    {
        super(props);

        this.state = {
            product: null,
            isLoggedIn: false
        };

        App.I.setPageTitle("Home");
    }

    public componentDidMount(): void
    {
        this.fetchData();
    }

    private async fetchData(): Promise<void>
    {
        const productService = new ProductService();
        const product: Product = await productService.getProductById(this.props.router.params.id);
        const user: User = UserService.user;

        this.setState({
            product,
            isLoggedIn: user !== null
        });
    }

    private onPurchaseProduct(): void 
    {
        App.I.addToCart(this.state.product);
        App.I.changePage(Page.StoreCart);
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="lg">

                    <Box sx={{
                        background: "#fff",
                        borderRadius: "3rem",
                        display: "flex",
                        flexDirection: "column",
                        padding: "2rem",
                        mt: 5,
                        mb: 5
                    }}>
                        <Box sx={{ width: "800px", maxWidth: "100%" }}>
                            <Typography component="h2" sx={{ m: 0, mb: 1, textTransform: "uppercase", fontSize: "0.8rem" }} fontWeight={300}>
                                {ProductType[this.state.product?.type]}
                            </Typography>

                            <Typography variant="h1" sx={{ mb: 2, wordBreak: "break-word" }}>
                                {this.state.product?.name}
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 2, wordBreak: "break-word" }}>
                                {this.state.product?.shortDescription}
                            </Typography>
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box sx={{ marginLeft: "auto" }}>
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: "18px", padding: "0.8rem 1.5rem" }}
                                    size="large"
                                    onClick={this.onPurchaseProduct.bind(this)}
                                >
                                    <AddShoppingCartIcon /> Purchase {" "}
                                    {this.state.product?.priceFormatted}
                                </Button>
                            </Box>
                        </Box>

                        {this.state.product?.gallery?.length > 0 ?
                            <Box sx={{ mt: 5, mb: 5, height: "300px", overflow: "hidden" }}>
                                <Swiper>
                                    {this.state.product.gallery.map(productImage =>
                                        <SwiperSlide>
                                            <img src={productImage.url} alt={productImage.alt} />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </Box>
                            : null
                        }

                        <Box sx={{ width: "800px", maxWidth: "100%" }}>
                            <Typography variant="h2" sx={{ mb: 2 }} fontWeight={600}>
                                Description
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 2, mt: 2, wordBreak: "break-word" }}>
                                {this.state.product?.description}
                            </Typography>
                        </Box>
                    </Box>

                    {/* 
                    <Typography variant="h1" sx={{ mt: 4, mb: 4, paddingTop: 5, paddingBottom: 2, }}>
                        You may also be interested in
                    </Typography>

                    <Grid container spacing={5}>
                        <Grid xs={12} lg={6}>
                            <FeaturedAsset
                                title={"Asset Lorem ipsum dolor sit amet consectetur adipi"}
                                description={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quibusdam accusamus soluta q"}
                                imageSrc={""}
                                price={"£3000"}
                                onClick={() => { }}
                            />
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <FeaturedAsset
                                title={"Asset Lorem ipsum dolor sit amei"}
                                description={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quibusdam accusamus soluta qua!"}
                                imageSrc={""}
                                price={"£3000"}
                                onClick={() => { }}
                            />
                        </Grid>
                    </Grid> 
                    */}
                </Container>
            </>
        );
    }
}

export default withRouter<StoreDetailPageProps>(StoreDetailPage);
