import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { App } from "../../App";
import { Page } from "../../Page";
import { withRouter } from "../withRouter";
import { RouterProps } from "../RouterProps";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { ReadyPlayMeAvatar } from "./ReadyPlayMeAvatar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CartChangedEvent } from "../../cart/CartChangedEvent";

export interface HeaderProps extends RouterProps
{
}

interface HeaderState 
{
    anchor: any;
    cartCount: number;
    user: User;
}

class Header extends Component<HeaderProps, HeaderState>
{
    constructor(props: HeaderProps)
    {
        super(props);

        this.state = {
            anchor: null,
            user: null,
            cartCount: null
        };
    }

    public componentDidMount(): void
    {
        App.I.addEventListener(CartChangedEvent.CART_UPDATED, this.onCartUpdated, this);

        this.setState({
            user: UserService.user,
            cartCount: App.I.cartCount
        });
    }

    private onCartUpdated(e: CartChangedEvent): void 
    {
        this.setState({
            cartCount: App.I.cartCount
        })
    }

    private onOpenMenu(event: React.MouseEvent<HTMLElement>): void
    {
        this.setState({
            anchor: event.currentTarget
        });
    }

    private onCloseMenu(event: React.MouseEvent<HTMLElement>): void
    {
        this.setState({
            anchor: null
        });
    }

    private onLogoutClicked(): void 
    {
        App.I.logout();
    }

    private onNavItemClicked(page: Page): void 
    {
        App.I.changePage(page);
    }

    private closeDropdown(): void 
    {
        this.setState({
            anchor: null
        });
    }

    public render()
    {
        const navActiveStyle: React.CSSProperties = {
            background: "#82ccbe",
            color: "black",
            borderRadius: "50px",
            padding: "5px 20px"
        };

        return (
            <>
                <AppBar position="static">
                    <Container maxWidth="xl">

                        <Toolbar disableGutters>
                            <Box>
                                <Typography
                                    variant="h2"
                                    noWrap
                                    component="a"
                                    href="/"
                                    title="VR Campus"
                                    sx={{
                                        mr: 2,
                                        color: "inherit",
                                        textDecoration: "none",
                                        fontWeight: 600,
                                        fontSize: "2.2rem"
                                    }}
                                >
                                    VR Campus Store
                                </Typography>
                            </Box>

                            <Box sx={{ flexGrow: 0, marginLeft: "auto", position: "relative" }}>

                                <Button
                                    title="Cart"
                                    onClick={this.onNavItemClicked.bind(this, Page.StoreCart)}
                                    variant="text"
                                    sx={{ mr: 2, color: "#fff", position: "relative" }}
                                >
                                    <ShoppingCartIcon />
                                    <Box sx={{
                                        position: "absolute",
                                        background: "rgb(130, 204, 190)",
                                        color: "#000",
                                        width: "20px",
                                        height: "20px",
                                        top: 0,
                                        right: 0,
                                        borderRadius: "100%",
                                        fontSize: this.state.cartCount > 99 ? "0.5rem" : "0.8rem",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        {this.state.cartCount > 99 ? "99+" : this.state.cartCount}
                                    </Box>
                                </Button>
                            </Box>

                            {this.state.user ?
                                <Box>
                                    <Button title="Profile" onClick={this.onOpenMenu.bind(this)} style={{ height: "50px" }}>
                                        <ReadyPlayMeAvatar
                                            interactable={false}
                                            user={this.state.user}
                                            style={{ width: "50px", height: "50px" }}
                                        />
                                    </Button>

                                    <Menu
                                        sx={{ mt: "45px" }}
                                        id="menu-appbar"
                                        anchorEl={this.state.anchor}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(this.state.anchor)}
                                        onClose={this.onCloseMenu.bind(this)}
                                    >
                                        <MenuItem onClick={this.onLogoutClicked.bind(this)}>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </Box>
                                : null
                            }
                        </Toolbar>
                    </Container>
                </AppBar >
            </>
        );
    }

    public componentWillUnmount(): void
    {
        App.I.removeEventListener(CartChangedEvent.CART_UPDATED, this.onCartUpdated);
    }
}

export default withRouter<HeaderProps>(Header);