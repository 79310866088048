import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Component } from "react";
import { ProductType } from "../../api/models/Product";

export interface FeaturedAssetProps
{
    title: string;
    description: string;
    imageSrc: string;
    price: string;
    type: ProductType;
    onClick: () => void;
}

interface FeaturedAssetState 
{
}

export class FeaturedAsset extends Component<FeaturedAssetProps, FeaturedAssetState>
{
    constructor(props: FeaturedAssetProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Box sx={{ background: "#fff", borderRadius: "3rem", display: "flex" }}>

                <Box sx={{ padding: "2rem", maxWidth: "calc(100% - 250px)" }}>
                    <Typography variant="h2" sx={{ mb: 2 }}>
                        {this.props.title}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                        {this.props.description}
                    </Typography>

                    <Typography variant="h4" fontWeight={600} sx={{ mb: 2 }}>
                        {this.props.price}
                    </Typography>

                    <Button variant="contained" onClick={this.props.onClick.bind(this)} sx={{ borderRadius: "18px" }}>
                        View {ProductType[this.props.type]}
                    </Button>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexGrow: 1,
                    width: "250px",
                    minHeight: "350px",
                    marginLeft: "auto",
                    backgroundSize: "cover",
                    borderRadius: "0 3rem 3rem 0",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${this.props.imageSrc})`
                }} />
            </Box>
        );
    }
}