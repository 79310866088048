// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";

export default ({ children }) =>
{
    return (
        <Swiper
            modules={[Navigation]} className="mySwiper"
            spaceBetween={50}
            slidesPerView={3}
            navigation={true}
        >
            {children}
        </Swiper>
    );
};