import { FileFormat } from "./FileObject";

export class Campus 
{
    constructor(
        private _id: number,
        private _name: string,
        private _description: string,
        private _thumbnail: FileFormat,
        private _icon: FileFormat,
    )
    {
    }

    public static fromJson(json: any): Campus
    {
        return new Campus(
            json.id,
            json.name,
            json.description,
            json.file,
            json.icon,
        );
    }

    public get id(): number { return this._id; }
    public get name(): string { return this._name; }
    public get description(): string { return this._description; }
    public get thumbnail(): FileFormat { return this._thumbnail; }
    public get icon(): FileFormat { return this._icon; }
}