import { Campus } from "./Campus";

export class User 
{
    private _userCampuses: Array<Campus> = [];

    constructor(
        private _id: number,
        private _email: string,
        private _username: string,
        private _readyplaymeUserId: number,
        private _readyplaymeGLBUrl: string,
        private _name: string,
        private _userRole: UserRole
    )
    {
    }

    public static fromJson(json: any): User
    {
        let user = new User(
            json.id,
            json.email,
            json.username,
            json.readyplayme_user_id,
            json.readyplayme_glb_url,
            json.name,
            json.role
        );

        if (json.user_campuses)
        {
            for (let i = 0; i < json.user_campuses.length; i++)
            {
                let c: any = json.user_campuses[i];
                let campus = new Campus(
                    c.id,
                    c.name,
                    c.description,
                    c.file,
                    c.icon,
                );
                user._userCampuses.push(campus);
            }
        }

        return user;
    }

    public get id(): number { return this._id; }
    public get email(): string { return this._email; }
    public get name(): string { return this._name; }
    public get username(): string { return this._username; }
    public get readyPlayMeUserId(): number { return this._readyplaymeUserId; }
    public get readyPlayMeGLBUrl(): string { return this._readyplaymeGLBUrl; }
    public get userCampuses(): Array<Campus> { return this._userCampuses; }
    public get role(): UserRole { return this._userRole; }
}

export interface UserRole
{
    name: string;
}