import { BaseAPI } from "./BaseAPI";
import { LoginRequest } from "./UserAPI";
import { Product } from "./models/Product";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface StoreCheckoutData
{
    productId: string;
    quantity: number;
}

export interface CheckoutRequest 
{
    products: Array<StoreCheckoutData>;
    campusIds: Array<number>;
    hasSubscriptions: boolean;
}

export interface PurchaseRequest 
{
    productIds: Array<number>;
    campusIds: Array<number>;
}

export class ProductAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async getProducts(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/store/products"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getCheckoutUrl(checkoutRequest: CheckoutRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/store/checkout"), this.headers);
        let response: APIResponse = await request.post(checkoutRequest);
        return response;
    }

    public async purchaseProducts(purchaseRequest: PurchaseRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/store/purchase"), this.headers);
        let response: APIResponse = await request.post(purchaseRequest);
        return response;
    }
}
