export enum Page 
{
    Store = "/",
    StoreDetail = "/product",
    StoreDetailRoute = "/product/:id",
    StoreCart = "/cart",
    PaymentComplete = "/cart/success",

    // Auth
    Login = "/login",
}

export enum PageCallback 
{
    Success = "success",
    Cancel = "cancelled"
}