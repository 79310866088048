import { Box, Typography } from "@mui/material";
import React, { Component } from "react";
import Button from "@mui/material/Button";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LinkIcon from '@mui/icons-material/Link';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { ProductType } from "../../api/models/Product";

export interface ProductItemProps
{
    title: string;
    description: string;
    imageSrc: string;
    imageAlt: string;
    price: string;
    type: ProductType;
    onViewClicked: () => void;
    onAddToCart: () => void;
}

interface ProductItemState 
{
}

export class ProductItem extends Component<ProductItemProps, ProductItemState>
{
    constructor(props: ProductItemProps)
    {
        super(props);

        this.state = {
        };
    }

    public render()
    {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                background: "#F0F0F0",
                borderRadius: "1rem",
            }}
                title={this.props.title}
            >
                <Box
                    role="img"
                    aria-label={this.props.imageAlt}
                    sx={{
                        width: "100%",
                        maxWidth: "100%",
                        minHeight: "250px",
                        height: "100%",
                        marginLeft: "auto",
                        borderRadius: "1rem 1rem 0 0",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundImage: `url(${this.props.imageSrc})`,
                        cursor: "pointer"
                    }}
                    onClick={this.props.onViewClicked.bind(this)}
                />

                <Box sx={{ padding: "1rem", width: "100%" }}>
                    <Typography component="h2" sx={{ m: 0, mb: 1, textTransform: "uppercase", fontSize: "0.8rem" }} fontWeight={300}>
                        {ProductType[this.props.type]}
                    </Typography>

                    <Typography variant="h5" sx={{ mb: 2, mt: 2, cursor: "pointer" }} fontWeight={600} onClick={this.props.onViewClicked.bind(this)}>
                        {this.props.title}
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2, wordBreak: "break-word" }} >
                        {this.props.description}
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h6" fontWeight={600}>
                            {this.props.price}
                        </Typography>

                        <Box sx={{ marginLeft: "auto" }}>
                            <Button variant="text" sx={{ borderRadius: "18px" }} onClick={this.props.onViewClicked.bind(this)}>
                                <LinkIcon />
                            </Button>

                            <Button variant="text" sx={{ borderRadius: "18px" }} onClick={this.props.onAddToCart.bind(this)}>
                                <AddShoppingCartIcon />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}