import React, { Component } from "react";

export interface ReadyPlayMeViewerProps
{
    src: string;
    zoomOnLoad?: boolean;
    interactable?: boolean;
    onLoadingProgress?: (progress: number) => void;
    onLoadingComplete?: () => void;
}

interface ReadyPlayMeViewerState
{
    loading: boolean;
    loadingProgress: number;
}

export class ReadyPlayMeViewer extends Component<ReadyPlayMeViewerProps, ReadyPlayMeViewerState>
{
    private ref: React.RefObject<HTMLElement>;

    constructor(props: ReadyPlayMeViewerProps)
    {
        super(props);

        this.state = {
            loading: true,
            loadingProgress: 0
        };

        this.ref = React.createRef();
    }

    public componentDidMount(): void
    {
        this.ref.current.addEventListener("load", this.onLoaded.bind(this));
        this.ref.current.addEventListener("progress", this.props.onLoadingProgress?.bind(this));
    }

    private onLoaded(): void 
    {
        if (this.props.zoomOnLoad == true)
        {
            this.setState({
                loading: false
            });
            // @ts-ignore
            this.ref.current.zoom(10);
        }

        if (this.props.onLoadingComplete)
        {
            this.props.onLoadingComplete();
        }
    }

    public render(): JSX.Element
    {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                {this.props.interactable === false ?
                    <>
                        {/* @ts-ignore */}
                        <model-viewer
                            src={this.props.src}
                            camera-orbit="auto auto 1%"
                            min-field-of-view="1deg"
                            shadow-intensity="1"
                            disable-zoom
                            disable-pan
                            touch-action={"none"}
                            ref={this.ref}
                            style={{ width: "100%", height: "100%" }}>
                        </model-viewer>
                    </>
                    :
                    <>
                        {/* @ts-ignore */}
                        <model-viewer
                            src={this.props.src}
                            camera-controls
                            auto-rotate
                            camera-orbit="auto auto 1%"
                            min-field-of-view="1deg"
                            shadow-intensity="1"
                            touch-action={"pan-y"}
                            ref={this.ref}
                            style={{ width: "100%", height: "100%" }}>
                        </model-viewer>
                    </>
                }
            </div>
        );
    }
}