import { AppStorage } from "../AppStorage";
import { Product } from "../api/models/Product";

export class Cart 
{
    private _cartItems: Array<Product> = [];
    private _storage: AppStorage;

    constructor()
    {
        this._storage = new AppStorage();
        this._cartItems = this._storage.cart ?? [];
    }

    public reset(): void
    {
        this._cartItems = [];
        this._storage.clearCart();
    }

    public addProduct(product: Product): boolean
    {
        let notInCart: boolean = this._cartItems.find((i => i.id == product.id)) == null;
        if (notInCart)
        {
            this._cartItems.push(product);
            this.save();
        }
        return notInCart;
    }

    public removeProduct(product: Product): void
    {
        let deleteIndex: number = this._cartItems.indexOf(product);
        if (deleteIndex > -1)
        {
            this._cartItems.splice(deleteIndex, 1);
        }
        this.save();
    }

    private save(): void { this._storage.saveCart(this._cartItems); }

    public get cartItems(): Array<Product> { return this._cartItems; }

    public get cartTotal(): string
    {
        let total: number = 0;
        this._cartItems.map(p => total += p.pricePence);
        return "£" + (total / 100);
    }

    public get cartCount(): number 
    {
        return this._cartItems.length;
    }
}
