import React, { Component } from "react";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import Typography from "@mui/material/Typography";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { App } from "../../App";
import Header from "../components/Header";

export interface NotFoundPageProps
{
}

interface NotFoundPageState 
{
}

export class NotFoundPage extends Component<NotFoundPageProps, NotFoundPageState>
{
    constructor(props: NotFoundPageProps)
    {
        super(props);

        this.state = {
        };

        App.I.setPageTitle("Not found");
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="xl">
                    <Typography component="h1" variant="h3" sx={{ mt: 4 }}>
                        404 - Page not found
                    </Typography>
                </Container>
            </>
        );
    }
}