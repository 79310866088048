import React, { Component } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { App } from "../../App";
import Header from "../components/Header";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FeaturedAsset } from "../components/FeaturedAsset";
import Box from "@mui/material/Box";
import { ProductItem } from "../components/AssetItem";
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Swiper from "../components/Swiper";
import { SwiperSlide } from "swiper/react";
import { CartItem } from "../components/CartItem";
import { Product } from "../../api/models/Product";
import { ProductService } from "../../services/ProductService";
import { Page } from "../../Page";
import { Campus } from "../../api/models/Campus";

export interface StoreCartPageProps
{
}

interface StoreCartPageState 
{
    cart: Array<React.ReactNode>;
    cartTotal: string;
    isLoggedIn: boolean;
    loading: boolean;
    campusList: Array<CampusList>;
    selectedCampusList: Array<CampusList>;
    invalid: boolean;
    error: string;
}

interface CampusList 
{
    campusId: number;
    label: string;
}

export class StoreCartPage extends Component<StoreCartPageProps, StoreCartPageState>
{
    private form: React.RefObject<HTMLFormElement>;
    private userService: UserService;
    private productService: ProductService;

    constructor(props: StoreCartPageProps)
    {
        super(props);

        this.state = {
            cart: null,
            cartTotal: null,
            campusList: null,
            selectedCampusList: null,
            isLoggedIn: false,
            loading: false,
            invalid: false,
            error: ""
        };

        this.form = React.createRef();
        this.userService = new UserService();
        this.productService = new ProductService();

        App.I.setPageTitle("Cart");
    }

    public componentDidMount(): void
    {
        this.fetchData();
    }

    private async fetchData(): Promise<void>
    {
        const user: User = UserService.user;
        const campuses: Array<Campus> = await this.getUserCampuses();

        this.buildCampusList(campuses, []);

        this.setState({
            cart: this.buildCart(App.I.cartItems),
            cartTotal: App.I.cartTotal,
            isLoggedIn: user !== null
        });
    }

    private async getUserCampuses(): Promise<Array<Campus>> 
    {
        try
        {
            return await this.userService.getUserCampuses();
        }
        catch (error)
        {
            return [];
        }
    }

    private onRemoveCartItem(p: Product): void 
    {
        App.I.removeFromCart(p);
        this.setState({
            cart: null,
        },
            // lazy.
            () => this.fetchData()
        );
    }

    private buildCart(products: Array<Product>): Array<React.ReactNode>
    {
        let nodes: Array<React.ReactNode> = [];

        products.map(p =>
            nodes.push(
                <CartItem
                    key={p.id}
                    product={p}
                    onRemoveClicked={this.onRemoveCartItem.bind(this, p)}
                />
            )
        );
        return nodes;
    }

    private onLoginClicked(): void 
    {
        App.I.changePage(Page.Login);
    }

    private onSubmitCartClicked(): void 
    {
        this.setState({
            loading: true
        });

        //this.getCheckoutUrl();
        this.submitCart();
    }

    private async submitCart(): Promise<void>
    {
        let productIds: Array<number> = App.I.cartItems.map(i => i.id);
        let campusIds: Array<number> = this.state.selectedCampusList.map(list => list.campusId);

        let success: boolean = await this.productService.purchaseProducts(productIds, campusIds);

        if (success)
        {
            App.I.changePage(Page.PaymentComplete);
        }
        else
        {
            this.setState({
                loading: false,
                error: "An error occurred whilst purchasing products"
            });
        }
    }

    private async getCheckoutUrl(): Promise<void>
    {
        let productService: ProductService = new ProductService();
        let campusIds: Array<number> = this.state.selectedCampusList.map(list => list.campusId);
        let checkoutUrl: string = await productService.getCheckoutUrlFromCartProducts(App.I.cartItems, campusIds);

        this.setState({
            loading: false
        });

        document.location.href = checkoutUrl;
    }

    private buildCampusList(campuses: Array<Campus>, selectedCampusIds: Array<number>): void
    {
        let campusListItems: Array<CampusList> = [];
        let defaultCampusListItems: Array<CampusList> = [];

        for (let i = 0; i < campuses.length; i++)
        {
            const campus: Campus = campuses[i];

            let userList: CampusList = {
                label: campus.name,
                campusId: campus.id
            };

            if (selectedCampusIds.indexOf(campus.id) > -1)
            {
                defaultCampusListItems.push(userList);
            }

            campusListItems.push(userList);
        }

        this.setState({
            campusList: campusListItems,
            selectedCampusList: defaultCampusListItems,
            invalid: (defaultCampusListItems.length === 0)
        });
    }

    private onCampusesChanged(e: Event, values: Array<CampusList>): void 
    {
        this.setState({
            selectedCampusList: values,
            invalid: (values.length === 0)
        });
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="lg">
                    <Grid container spacing={5} sx={{ mt: 4 }}>
                        <Grid xs={12} lg={6}>
                            <Box sx={{ background: "#fff", padding: 4, borderRadius: "2rem" }}>
                                <Typography variant="h2" sx={{ mb: 4 }} fontWeight={600}>
                                    Your Cart
                                </Typography>

                                <Box>
                                    {this.state.cart}
                                </Box>
                            </Box>
                        </Grid>

                        <Grid xs={12} lg={6}>
                            <Box>
                                <Box sx={{ background: "#fff", padding: 4, borderRadius: "2rem" }}>
                                    <Typography variant="body1" sx={{ mb: 2 }} fontWeight={600}>
                                        Select your purchasing campus or campuses
                                    </Typography>

                                    {this.state.isLoggedIn ?
                                        <>
                                            {this.state.campusList ?
                                                <Autocomplete
                                                    multiple
                                                    disablePortal
                                                    autoComplete={false}
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextField {...params} label="Campus" />}
                                                    onChange={this.onCampusesChanged.bind(this)}
                                                    options={this.state.campusList}
                                                    value={this.state.selectedCampusList}
                                                />
                                                : null
                                            }

                                            <Typography variant="body2" sx={{ mt: 2 }} fontWeight={600}>
                                                Total: {this.state.cartTotal}
                                            </Typography>

                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                {
                                                    this.state.error.length > 0 ?
                                                        <Typography variant="body1" sx={{ color: "#FC522A", maxWidth: "50%" }} fontWeight={600}>
                                                            {this.state.error}
                                                        </Typography>
                                                        : null
                                                }
                                                <Box sx={{ marginLeft: "auto" }} component={"form"} method="post" action="http://localhost:1337/api/store/checkout">
                                                    <Button
                                                        variant="contained"
                                                        sx={{ borderRadius: "18px" }}
                                                        size="large"
                                                        onClick={this.onSubmitCartClicked.bind(this)}
                                                        disabled={this.state.loading || this.state.invalid}
                                                    >
                                                        <AddShoppingCartIcon sx={{ mr: 2 }} /> Purchase
                                                        {" "}
                                                        {this.state.cartTotal}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </>
                                        :
                                        <Box>
                                            <Typography>To continue this purchase, please login to your VR Campus account.</Typography>
                                            <Button variant="contained" onClick={this.onLoginClicked.bind(this)} sx={{ mt: 2 }}>Login</Button>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }
}
