import { BaseAPI } from "./BaseAPI";
import { APIResponse } from "./rest/APIResponse";
import { RestRequest } from "./rest/RestRequest";

export interface LoginRequest
{
    identifier: string;
    password: string;
}

export interface RegisterRequest
{
    username: string;
    email: string;
    password: string;
    passwordConfirmed: string;
}

export interface UpdateUserRequest
{
    id?: number;
    name?: string;
    email?: string;
    readyplayme_user_id?: number;
    readyplayme_glb_url?: string;
}

export interface ForgotPasswordRequest
{
    email: string;
}

export class UserAPI extends BaseAPI
{
    constructor(
        baseUrl: string,
        headers: { [name: string]: string } = {}
    )
    {
        super(baseUrl, headers);
    }

    public async login(requestData: LoginRequest): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/auth/local"), this.headers);
        let response: APIResponse = await request.post(requestData);
        return response;
    }

    public async getUser(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/users/me?populate=*"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }

    public async getUserCampuses(): Promise<APIResponse>
    {
        let request: RestRequest = new RestRequest(this.url("/user-campuses"), this.headers);
        let response: APIResponse = await request.get();
        return response;
    }
}
