import React, { Component } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { UserService } from "../../services/UserService";
import { User } from "../../api/models/User";
import { App } from "../../App";
import Header from "../components/Header";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FeaturedAsset } from "../components/FeaturedAsset";
import Box from "@mui/material/Box";
import { ProductItem } from "../components/AssetItem";
import { Page } from "../../Page";
import { ProductService } from "../../services/ProductService";
import { Product, ProductType } from "../../api/models/Product";

export interface HomeStorePageProps
{
}

interface HomeStorePageState 
{
    user: User;
    loading: boolean;
    products: Array<React.ReactNode>;
    collections: Array<React.ReactNode>;
}

export class HomeStorePage extends Component<HomeStorePageProps, HomeStorePageState>
{
    constructor(props: HomeStorePageProps)
    {
        super(props);

        this.state = {
            user: null,
            loading: true,
            products: null,
            collections: null
        };

        App.I.setPageTitle("Home");
    }

    public componentDidMount(): void
    {
        this.fetchData();
        this.setState({
            user: UserService.user,
        });
    }

    private async fetchData(): Promise<void>
    {
        let productService = new ProductService();
        let products = await productService.getProducts();
        let collections = products.filter(p => p.type === ProductType.Collection);

        this.setState({
            collections: this.buildCollections(collections),
            products: this.buildProducts(products),
            loading: false
        });
    }

    private onViewProduct(p: Product): void 
    {
        App.I.changePage(Page.StoreDetail, "/" + p.id.toString());
    }

    private onAddProductToCart(p: Product): void 
    {
        App.I.addToCart(p);
    }

    private buildProducts(products: Array<Product>): Array<React.ReactNode>
    {
        let nodes = [];

        products.map(p => nodes.push(
            <Grid xs={12} md={3} key={p.id}>
                <ProductItem
                    key={"pi" + p.id}
                    title={p.name}
                    description={p.shortDescription}
                    imageSrc={p.thumbnail.url}
                    imageAlt={p.thumbnail.alt}
                    price={p.priceFormatted}
                    type={p.type}
                    onViewClicked={this.onViewProduct.bind(this, p)}
                    onAddToCart={this.onAddProductToCart.bind(this, p)}
                />
            </Grid>
        ));

        return nodes;
    }

    private buildCollections(products: Array<Product>): Array<React.ReactNode>
    {
        let nodes = [];

        products.map(p => nodes.push(
            <Grid xs={12} md={6} key={p.id}>
                <FeaturedAsset
                    key={"fa" + p.id}
                    title={p.name}
                    description={p.shortDescription}
                    imageSrc={p.thumbnail.url}
                    price={p.priceFormatted}
                    type={p.type}
                    onClick={this.onViewProduct.bind(this, p)}
                />
            </Grid>
        ));

        return nodes;
    }

    public render(): JSX.Element
    {
        return (
            <>
                <Header />

                <Container component="main" maxWidth="lg">
                    {this.state.collections?.length > 0 ?
                        <>
                            <Typography variant="h1" sx={{ mt: 2, mb: 2, paddingTop: 5, paddingBottom: 2, fontSize: "2.5rem" }}>
                                Discover <strong>new</strong> asset collections
                            </Typography>

                            <Grid container spacing={5}>
                                {this.state.collections}
                            </Grid>
                        </>
                        : null
                    }
                    <Typography variant="h1" sx={{ mt: 4, mb: 0, }}>
                        All assets
                    </Typography>

                    <Box sx={{ background: "#fff", borderRadius: "3rem", display: "flex", padding: "2rem", mt: 4, mb: 2, width: "100%" }}>

                        <Grid container spacing={5} sx={{ width: "100%" }}>

                            {this.state.products}
                        </Grid>
                    </Box>
                </Container>
            </>
        );
    }
}
