import { ProductAPI } from "./ProductAPI";
import { UserAPI } from "./UserAPI";

export class AppAPI
{
    private static _instance: AppAPI;

    private _baseUrl: string;
    private _users: UserAPI;
    private _products: ProductAPI;

    constructor(baseUrl: string)
    {
        if (AppAPI._instance != null)
        {
            throw new Error("AppAPI ** Only one instance allowed");
        }

        AppAPI._instance = this;
        this._baseUrl = baseUrl;

        let sharedHeaders: { [name: string]: string } =
        {
            "Content-Type": "application/json"
        };

        this._users = new UserAPI(this._baseUrl, sharedHeaders);
        this._products = new ProductAPI(this._baseUrl, sharedHeaders);
    }

    public setAuthHeader(token: string): void
    {
        let headerKey: string = "Authorization";
        let headerValue: string = "Bearer " + token;

        this._users.setHeader(headerKey, headerValue);
        this._products.setHeader(headerKey, headerValue);
    }

    public static get I(): AppAPI { return AppAPI._instance; }
    public get baseUrl(): string { return this._baseUrl; }
    public get users(): UserAPI { return this._users; }
    public get products(): ProductAPI { return this._products; }
}
