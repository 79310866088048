import { ForgotPasswordRequest, LoginRequest, RegisterRequest, UpdateUserRequest } from "../api/UserAPI";
import { AppAPI } from "../api/AppAPI";
import { APIResponse } from "../api/rest/APIResponse";
import { APIErrorResponseHandler } from "../api/APIErrorResponseHandler";
import { Product } from "../api/models/Product";
import { StoreCheckoutData } from "../api/ProductAPI";
import { alertClasses } from "@mui/material";

export class ProductService 
{
    public async getProducts(): Promise<Array<Product>>
    {
        const response: APIResponse = await AppAPI.I.products.getProducts();
        APIErrorResponseHandler.validate(response);

        let products: Array<Product> = [];

        for (let i = 0; i < response.json.length; i++)
        {
            const product: Product = Product.fromServerJson(response.json[i]);
            products.push(product);
        }

        return products;
    }

    public async getProductById(id: number): Promise<Product>
    {
        const response: APIResponse = await AppAPI.I.products.getProducts();
        APIErrorResponseHandler.validate(response);

        let products: Array<Product> = [];

        for (let i = 0; i < response.json.length; i++)
        {
            const product: Product = Product.fromServerJson(response.json[i]);
            products.push(product);
        }

        return products.filter(p => p.id == id)[0];
    }

    public async purchaseProducts(productIds: Array<number>, campusIds: Array<number>): Promise<boolean>
    {
        try
        {
            let response: APIResponse = await AppAPI.I.products.purchaseProducts({
                productIds: productIds,
                campusIds: campusIds
            });
            return response.success;
        }
        catch (e)
        {
            return false;
        }
    }

    public async getCheckoutUrlFromCartProducts(products: Array<Product>, campusIds: Array<number>): Promise<string> 
    {
        let stripeProductIds = [];

        products.map(p => stripeProductIds.push(p.stripeProductId));
        let hasSubscriptionProducts = products.filter(p => p.isSubscriptionProduct === true);
        let hasSubscriptions: boolean = hasSubscriptionProducts.length > 0;
        let productsRequest = this.formatIntoRequest(stripeProductIds);

        const response: APIResponse = await AppAPI.I.products.getCheckoutUrl({
            hasSubscriptions: hasSubscriptions,
            products: productsRequest,
            campusIds: campusIds,
        });

        APIErrorResponseHandler.validate(response);

        return response.json.url
    }

    private formatIntoRequest(ids: string[]): StoreCheckoutData[]
    {
        const counts: { [key: string]: number } = {};

        // Count the occurrences of each ID
        for (const id of ids)
        {
            counts[id] = (counts[id] || 0) + 1;
        }

        // Convert the counts object into an array of { id, quantity }
        const result: StoreCheckoutData[] = [];
        for (const id in counts)
        {
            result.push({ productId: id, quantity: counts[id] });
        }

        return result;
    }
}
